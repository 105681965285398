<template>
	<div v-bind:class="{ active: isActive, candle: true, lighting: isLighting, placing: isPlacing }" @click="handleClick">
		<div class="layer1">
			<div v-if="inHolder && (candle.empty || candle.isPlacing)" class="candle-base empty"></div>
		</div>
		<div class="layer2">
			<div v-if="!candle.empty" :class="'candle-base ' + baseClass"></div>
			<div v-if="!candle.empty && (!isLit || isLighting)" class="candle-wick unlit" :style="{ transform: 'rotate3d(0, 0, 1, ' + candle.wick_angle + 'deg)' }"></div>
			<div v-if="!candle.empty && isLit" class="candle-wick lit" :style="{ transform: 'rotate3d(0, 0, 1, ' + candle.wick_angle + 'deg)' }"></div>
			<div v-if="!candle.empty && isLit" class="flame" ref="flame"  :style="{ 'animation-delay': '0, ' + flameDelay + 's'}"></div>
		</div>
		<div v-if="!candle.empty && showName" class="name"><span>{{candle.name}}</span></div>
	</div>
</template>

<script>
	import { TimelineMax, TweenLite } from 'gsap'
	import helpers from '../helpers'
	export default {
		props: ['candle', 'index', 'showName', 'isLighting', 'isLit', 'inHolder', 'click'],
		computed: {
			isActive() {
				return this.candle && this.currentCandle ? (this.currentCandle.id == this.candle.id) : false
			},
			isPlacing() {
				return this.candle && this.candle.isPlacing
			},
			flameDelay() {
				return this.inHolder ? helpers.getRandomFloat(0, 0.5) : 0
			},
			baseClass() {
				if (this.candle.empty)
					return 'empty'
				return this.inHolder ? this.variant : 'no-holder'
			},
			wickClass() {
				return this.isLit ? 'lit' : 'unlit'
			}
		},
		mounted() {
			setTimeout(() => {
				this.candle.isPlacing = false
			}, 1000)
		},
		methods: {
			handleClick(event) {
				event.preventDefault()
				if (this.click)
					this.click(this.candle)
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import "../css/shared.scss";

	.candle {
		width: 100%;
		height: 100%;
		text-align: center;
		position: relative;

		cursor: pointer;

		&.placing .layer2 {
			opacity: 0;
			animation: fadeIn 1s ease-in forwards;	
		}

		.candle-wick {
			transform: translate3d(0, 0, 0);
			position: absolute;
			top: 2%;
			left: 0;
			width: 100%;
			height: 30%;
			background: url(../images/Just-Wick-unlit-zoomed.png) center no-repeat;
			background-size: contain;
			&.lit {
				background: url(../images/Just-Wick.png) center no-repeat;
				background-size: contain;
			}
		}

		.flame {
			position: absolute;
			top: -80%;
			left: 0;
			width: 100%;
			height: 150%;
		}

		.name {
			position: absolute;
			left: 10%;
			top: 75%;
			right: 10%;
			width: 80%;
			color: #fff;
			border-radius: 4px;
			background: rgba(39,39,41,0.93);
			padding: 5% 10%;
			vertical-align: middle;
			box-sizing: border-box;
			font-size: rel-size(13px);
			line-height: 1;
			text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3);
			display: none;
			span {
				display: table-cell;
				vertical-align: middle;
			}
		}
		.candle-base {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translate3d(0, 0, 0);
			&,
			&.candle-variant-1 {
				background: url(../images/Cup-and-Candle-1920px-1.png) center no-repeat;
				background-size: auto 100%;
			}
			&.candle-variant-2 {
				background: url(../images/Cup-and-Candle-1920px-2.png) center no-repeat;
				background-size: auto 100%;
			}
			&.candle-variant-3 {
				background: url(../images/Cup-and-Candle-1920px-3.png) center no-repeat;
				background-size: auto 100%;
			}
			&.candle-variant-4 {
				background: url(../images/Cup-and-Candle-1920px-4.png) center no-repeat;
				background-size: auto 100%;
			}
			&.no-holder {
				background: url(../images/Tealight-no-wick.png) center no-repeat;
				background-size: auto 100%;
			}
			&.empty {
				background: url(../images/Cup-Empty-Zoomed-200.png) center no-repeat;
				background-size: auto 100%;
			}
		}
	}

	@keyframes flame {
		0%   { transform: scaleY(1); }
		50%   { transform: scaleY(1.1); }
		100% { transform: scaleY(1); }
	}

	.lighting {
		.candle-wick.unlit {
			animation: fadeOut 5s ease-out forwards;	
		}

		.candle-wick.lit {
			animation: fadeIn 5s ease-in forwards;	
		}
	}

	.flame {
		opacity: 0;
		background: url(../images/Flame-and-Glow.png) center no-repeat;
		background-size: contain;
		animation: fadeIn 0.01s ease-in 0s forwards, flame 0.2s ease-in-out 0s infinite;
		.lighting & {
			animation: fadeIn 1s ease-in 0s forwards, flame 0.2s ease-in-out 0s infinite;
		}
	}

	#application:not(.mobile) .candle:hover .name, #application.mobile .candle .name {
		display: table;
	}

	
</style>

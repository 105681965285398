<template>
	<nav>
		<button @click="toggleMenu" :class="{open: showMenu}">Menu</button>
		<ul v-if="navigationLinks.length && showMenu">
			<li v-for="navigationLink in navigationLinks">
				<a :href="navigationLink.url" target="_blank">{{navigationLink.title}}</a>
			</li>
		</ul>
	</nav>
</template>

<script>
	import candlesApi from '../api/candlesApi'
	export default {
		methods: {
			toggleMenu(e) {
				e.stopPropagation()
				e.preventDefault()
				this.showMenu = !this.showMenu
			},
			closeMenu(){
				this.showMenu = false
			}
		},
		created() {
			candlesApi.getNavigationLinks().then((response) => {
				this.navigationLinks = response.data
			})
		},
		data() {
			return {
				showMenu: false,
				navigationLinks: []
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../css/shared.scss";

	nav {
		position: absolute;
		top: rel-size(24px);
		left: rel-size(24px);
		z-index: 3;
		@media (max-width: $mobile-max-width) {
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	button {
		z-index: 1;
    position: relative;
		margin-top: rel-size(24px);
		margin-left: rel-size(24px);
		border: none;
		text-indent: -4000px;
		display: block;
		width: rel-size(32px);
		height: rel-size(23px);
		background: transparent url("../images/Burger Menu.svg") center no-repeat;
		background-size: contain;
		&:focus {
			outline: none;
		}
		&.open {
			background: transparent url("../images/Burger Menu Close.svg") center no-repeat;
			background-size: contain;
		}
	}
	ul {
		position: absolute;
		top: 100%;
		left: 0;
		margin: 0;
		list-style: none;
		background: #0E152F;
		padding: rel-size(13px) rel-size(26px);
		display: block;
    box-sizing: border-box; 

		@media (max-width: $mobile-max-width) {
       width: 100%;
			 left: 0;
		   top: 0;
		}

		li {
			a {
				color: #707590; 
				font-size: rel-size(24px);
				line-height: rel-size(48px);
				white-space: nowrap;
				text-decoration: none;
			}
		}
	}
</style>

<template>
	<div id="light-candle" :style="{'min-height': $parent.appHeight + 'px'}">
		<div class="content">
			<div class="candle-wrapper" v-if="activateCandleStatus == 'success' && candle.status == 'active'">
				<div class="candle-position">
					<candle :candle="candle" :isLit="false" :click="candleClick" />
				</div>
				<div class="candle-content">
					<h2 class="name">{{candle.name}}</h2>
					<div class="message">
						{{candle.message}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import helpers from '../helpers'
	import Candle from './Candle'
	export default {
		props: ['candleGuid', 'transId', 'amount'],
		computed: {
			...mapState({
				candle: state => state.candles.candle,
				error: state => state.candles.error,
				activateCandleStatus: state => state.candles.status.activateCandle
			})
		},
    updated: function(){
			if (!this.candle) return
      console.log(this.candleGuid)
      console.log(this.candle.donation_id)
      if(this.candle.donation_id == null) {
        console.log('null so gonna track')
        this.addItem()
        this.addTransaction()
        this.send()
        this.track()
        fbq('track', 'Donate', {
          value: this.amount,
          currency: 'GBP',
        })
        console.log('trakd in facebook')
        this.$store.dispatch('candles/trackCandle', { guid: this.candleGuid, q: document.location.hash.replace(/^.*\?/, '') })
      } else {
        console.log('not null so not gonna track')
      }
    },
		methods: {
      addItem() {
        this.$ga.ecommerce.addItem({
          id: this.transId,                     // Transaction ID. Required.
          name: 'Donation to votive appeal',    // Product name. Required.
          sku: 'Donation to votive appeal',                 // SKU/code.
          category: 'Single gift',         // Category or variation.
          price: this.amount,       // Unit price.
          quantity: '1',                   // Quantity.
          currency: 'GBP'
        })
      },
      addTransaction() {
        this.$ga.ecommerce.addTransaction({
          id: this.transId,
          affiliation: 'Donation to votive appeal',
          revenue: this.amount,
          shipping: '0.00',
          tax: '0.00'
        })
      },
      send() {
        this.$ga.ecommerce.send({
        })
      },
      track() {
        this.$ga.page('/singlegiftconfirmvotivestand') 
      },
			candleClick() {
				this.$router.push({name: 'ready-to-place-candle', params: { candleGuid: this.candle.guid }})
			},
		},
		components: {
			Candle
		},
		created() {
			this.$store.dispatch('candles/activateCandle', { guid: this.candleGuid, q: document.location.hash.replace(/^.*\?/, '') })
		}
	}
</script>

<style lang="scss" scoped>
	@import "../css/shared.scss";

	#light-candle {
		@extend .modal;
	}


	form {
		position: absolute;
		top: 65%;
		left: 0;
		width: 100%;
		text-align: center;

		input, textarea {
			border: none;
			border-radius: 7px;
			background: rgba(255, 255, 255, 0.26);
			padding: 0.5em 1em;
			color: #9EABAD;
			text-align: center;
			display: inline-block;
			width: 40%;
			margin: auto;
			vertical-align: middle;
		}

		input {
			font-size: 30px;
		}

		textarea {
			font-size: 14px;
			height: 5em;
		}

		.name, .message {
			color: #9EABAD;
		}

		.name {
			font-size: 30px;
		}

		.message {
			font-size: 14px;
		}

		.field {
			margin-top: 2em;
		}

		button {
			vertical-align: middle;
      background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23707590'%2F%3E%3C%2Fsvg%3E") center no-repeat;
			background-size: cover;
			border: none;
			text-indent: -4000px;
			width: 3em;
			height: 3em;	
		
		}
	}

	.add-donation {
		@extend .cta;
	  position: absolute;
    top: 25%;
    left: 50%;
    width: 313px;
    transform: translateX(-50%);	
	}

</style>

<template>
	<div id="light-candle" :style="{'min-height': $parent.appHeight + 'px'}">
		<div class="content">
			<div class="candle-position">
				<candle v-if="newCandle" :candle="newCandle" />
			</div>
			<form class="candle-content">
				<div class="field field-name">
					<div class="input" v-if="$route.name == 'enter-name'">
						<input class="name" type="text" v-model="name" placeholder="Who you're praying for" @blur="onNameBlur" ref="nameInput" />
						<button @click.prevent="onNameBlur">Next</button>
					</div>
					<h2 v-if="$route.name != 'enter-name'" class="name" @click="onNameClick">{{name}}</h2>
				</div>
				<div class="field field-message">
					<div class="input" v-if="$route.name == 'enter-message'">
						<textarea maxlength="150" class="message" v-model="message" placeholder="(Optional) Your message or prayer" ref="messageInput"></textarea>
						<button @click.prevent="onMessageNext">Next</button>
					</div>
					<div v-if="$route.name != 'enter-message'" class="message" @click="onMessageClick">{{message}}</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import helpers from '../helpers'
	import Candle from './Candle'

	export default {
		computed: {
			...mapState({
				candle: state => state.candles.candle,
				newCandle: state => state.candles.newCandle,
				error: state => state.candles.error,
				createCandleStatus: state => state.candles.status.createCandle
			}),
			name: {
				get() { return this.$store.state.candles.newCandle.name },
				set(name) { this.$store.commit('candles/setNewCandleName', name) }
			},
			message: {
				get() { return this.$store.state.candles.newCandle.message },
				set(message) { this.$store.commit('candles/setNewCandleMessage', message) }
			}
		},
    mounted: function(){
      this.track()
    },
		methods: {
      track() {
        this.$ga.page(this.$router)
      },
			onNameBlur() {
				if (this.newCandle.name) {
					this.$router.push({name: 'enter-message'})
					this.$nextTick(() => {
						if (this.newCandle.message)
							this.$refs.messageInput.focus()
						else
							this.$refs.messageInput.blur()
					})
				}
			},
			onNameClick() {
				this.$router.push({name: 'enter-name'})
				this.$nextTick(() => {
					this.$refs.nameInput.focus()
				})
			},
			onMessageNext() {
				if (this.newCandle.name)
					this.$router.push({name: 'donation'})
			},
			onMessageClick() {
				this.$router.push({name: 'enter-message'})
				this.$nextTick(() => {
					this.$refs.messageInput.focus()
				})
			}
		},
		watch: {
			createCandleStatus() {
				if (this.createCandleStatus == 'success') {
					if (this.candle.redirect_uri.match(/^https?:\/\//)) {
						document.location.href = this.candle.redirect_uri
					} else {
						// Local url requires reload
						this.$router.push(this.candle.redirect_uri)
						window.location.reload(true)
					}
				}
			}
		},
		components: {
			Candle
		},
		created() {
			this.$store.commit('candles/setNewCandle', {
				name: '',
				message: '',
				wick_angle: helpers.getRandomFloat(-30, 30),
				variant: 'candle-variant-' + helpers.getRandomInt(1, 4)
			})
			if (this.$route.name == 'enter-message' && this.name == '')
				this.$router.push({name: 'enter-name'})
			else if (this.$route.name == 'donation' && this.name == '' || this.message == '')
				this.$router.push({name: 'enter-name'})
		}
	}
</script>

<style lang="scss" scoped>
	@import "../css/shared.scss";

	#light-candle {
		@extend .modal;
	}

	form {
		width: rel-size(384px);
		box-sizing: border-box;
		margin: auto;
		margin-bottom: rel-size(10px);
		text-align: center;

		.field {
			position: relative;

			input, textarea {
				border: none;
				border-radius: 7px 0 0 7px;
				background: rgba(255, 255, 255, 0.26);
				color: #9EABAD;
				text-align: center;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				margin: auto;
				vertical-align: middle;
				outline: none;
			}

			.input {
				position: relative;
				left: rel-size(-50px)!important;
			}

			input.name {
				height: rel-size(55px);
				line-height: rel-size(55px);
			}

			textarea {
				height: rel-size(100px);
				overflow: auto;
				resize: none;
				vertical-align: middle;
				font-family: 'Montserrat', sans-serif;
				padding: 1em;
			}

			button {
				vertical-align: middle;
        background: #EB6008;
				border: none;
				width: rel-size(100px);
				margin-top: rel-size(5px);
				position: absolute;
				top: 50%;
				left: 100%;
				transform: translateY(-50%);
        color: #e7c5b0;
				font-size: rel-size(20px);
        border-radius: 0 7px 7px 0;
			}
			&.field-name button {
				height: rel-size(55px);
			}
			&.field-message button {
				height: rel-size(100px);
			}
		}
	}

	.add-donation {
		@extend .cta;
		position: absolute;
    top: rel-size(380px);
    left: 50%;
    width: rel-size(313px);
    transform: translateX(-50%);	
		z-index: 1;
	}

	.bypass {
		margin-bottom: 1em;
	}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import VotiveStand from '../components/VotiveStand'
import LightCandle from '../components/LightCandle'
import LightYourCandle from '../components/LightYourCandle'
import ViewCandle from '../components/ViewCandle'
import ReadyToPlaceCandle from '../components/ReadyToPlaceCandle'

import LightCandleAction from '../components/actions/LightCandleAction'
import LightYourCandleAction from '../components/actions/LightYourCandleAction'
import BackToStandAction from '../components/actions/BackToStandAction'

import VueAnalytics from 'vue-analytics'

import ReadyToPlaceCandleAction from '../components/actions/ReadyToPlaceCandleAction'
import PlaceYourCandleAction from '../components/actions/PlaceYourCandleAction'
import ConfirmationAction from '../components/actions/ConfirmationAction'
import DonationAction from '../components/actions/DonationAction'
import EnterNameAction from '../components/actions/EnterNameAction'
import EnterMessageAction from '../components/actions/EnterMessageAction'

import DonationConfirm from '../components/DonationConfirm'
import LightCandleNav from '../components/LightCandleNav'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
		{
			name: 'root',
			path: '/',
			components: {
			 'actions': LightCandleAction
			}
		},
    {	
			name: 'view-candle',
			path: '/view-candle/:guid',
			components: {
			 'actions': BackToStandAction,
			 'modal': ViewCandle
			},
		},
    {	
			name: 'enter-name',
			path: '/enter-name',
			components: {
			 'actions': EnterNameAction,
			 'modal': LightCandle,
			 'footer': LightCandleNav
			},
		},
    {	
			name: 'enter-message',
			path: '/enter-message',
			components: {
			 'actions': EnterMessageAction,
			 'modal': LightCandle,
			 'footer': LightCandleNav
			},
		},
    {	
			name: 'donation',
			path: '/donation',
			components: {
			 'actions': DonationAction,
			 'modal': LightCandle,
			 'footer': LightCandleNav
			},
		},
    {
			name: 'light-your-candle',
			path: '/light-your-candle',
			components: {
				'modal': LightYourCandle,
			  'actions': LightYourCandleAction,
			  'footer': LightCandleNav
			},
      props: {
        'modal': (route) => ({ candleGuid: route.query.votiveid, transId: route.query.transId, amount: route.query.amount })
      }
		},
    {
			name: 'ready-to-place-candle',
			path: '/ready-to-place-candle/:candleGuid',
			components: {
				'modal': ReadyToPlaceCandle,
			  'actions': ReadyToPlaceCandleAction,
			 	'footer': LightCandleNav
			}
		},
    {
			name: 'place-your-candle',
			path: '/place-your-candle/:candleGuid',
			components: {
			  'actions': PlaceYourCandleAction
			}
		},
    {
			name: 'confirmation',
			path: '/confirmation/:candleGuid',
			components: {
			  'actions': ConfirmationAction
			}
    },
    {
      name: 'donation-confirm',
      path: '/donation-confirm',
      components: {
        'footer': LightCandleNav,
        'modal': DonationConfirm
      },
      props: {
        'modal': (route) => ({ candleGuid: route.query.votiveid })
      }
    }
  ]
})

Vue.use(VueAnalytics, {
  id: 'UA-1849208-1',
  ecommerce: {
    enabled: true
  },
  router,
  autoTracking: {
    page: false
  }
})


export default router

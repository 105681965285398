<template>
	<div id="view-candle" @click="handleClick" v-if="candle" :style="{'min-height': $parent.appHeight + 'px'}">
		<div class="content">
			<div class="candle-wrapper" v-if="getCandleStatus == 'success'">
				<div class="candle-position">
					<candle :candle="candle" :isLit="true" />
				</div>
				<div class="candle-content">
					<h2 class="name">{{candle.name}}</h2>
					<div class="message">
						{{candle.message}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import Candle from './Candle'
	export default {
		components: {
			Candle
		},
		computed: {
			...mapState({
				candle: state => state.candles.candle,
				getCandleStatus: state => state.candles.status.getCandle
			})
		},
    mounted: function(){
      this.track()
    },
		methods: {
      track() {
        this.$ga.page(this.$router) 
      },
			handleClick() {
				this.$router.push({name: 'root'})
			}
		},
		created() {
			this.$store.dispatch('candles/getCandle', this.$route.params.guid)
		}
	}
</script>
<style lang="scss" scoped>
	@import "../css/shared.scss";

	#view-candle {
		@extend .modal;
	}

</style>

<template>
	<ol ref="stepList" class="steps">
		<li v-for="step, i in steps" :class="{active: isActive(step.route, i) }" @click="handleClick(step.route)">
			<span class="index">{{i}}</span><span class="title">{{step.title}}</span>
		</li>
	</ol>
</template>

<script>
	import { mapState } from 'vuex'
	export default {
		mounted() {
			//window.addEventListener('resize', this.onResize)
		},
		methods: {
			/*onResize() {
				console.log('parent!', this.$parent.$refs.cafodLogo.getBoundingClientRect(), this.$refs.stepList.getBoundingClientRect()) 
				if (this.$parent.$refs.cafodLogo.getBoundingClientRect().right > this.$refs.stepList.getBoundingClientRect().left) {
					this.stepClass = 'above'
				} else {
					this.stepClass = 'below'
				} 
			},*/
			handleClick(route) {
				let allowed = false
				switch (route) {
					case 'enter-name':
						allowed = true 
						break
					case 'enter-message':
						allowed = this.newCandle.name ? true : false
						break
					case 'donation':
						allowed = this.newCandle.name && this.newCandle.message ? true : false
						break
				}
				if (allowed && ['enter-name', 'enter-message', 'donation'].indexOf(this.$route.name) !== -1)
					this.$router.push({name: route})
			},
			isActive(route, i) {
				let activeStep = null
				for (let k in this.steps) {
					if (this.steps[k].route == this.$route.name)
						activeStep = k
				} 
				
				return i >= activeStep
			}
		},
		computed: {
			...mapState({
				newCandle: state => state.candles.newCandle
			})
		},
		data() {
			return {
				steps: {
					1: {
						title: "Who You're Praying For",
						route: 'enter-name'
					},
					2: {
						title: "Your Message",
						route: "enter-message"
					},
					3: {
						title: "Your Details",
						route: "donation"
					},
          4: {
            title: "Light Your Candle",
            route: "light-your-candle"
          },
          5: {
            title: "Place Your Candle",
            route: "ready-to-place-candle"
          }
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../css/shared.scss";

	ol {
		list-style: none;
		padding: 0;
		margin: 0;
		position: absolute;
		left: 50%;
		top: -50%;
		transform: translate(-50%, -50%);
		height: rel-size(40px);
		white-space: nowrap;
		width: rel-size(590px);

		li {
			color: #fff;
			background: #596393;
			border-radius: 13px;
			width: rel-size(110px);
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			position: relative;
			margin-left: rel-size(10px);
			
			&:before {
				content: '';
				display: inline-block;
				height: rel-size(10px);
				width: rel-size(10px);
				background: #596393;
				position: absolute;
				top: 50%;
				left: rel-size(-10px);
				transform: translateY(-50%);
			}

			&:first-child {
				margin-left: 0;
			}

			&:first-child::before {
				display: none;
			}
	
			&.active {
				background: #282E4A;
				&:before {
					background: #282E4A;
				}
			}

			span {
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				line-height: 1;
			}

			.index {
				font-size: rel-size(14px);
				left: 0.75em;
			}

			.title {
				font-size: rel-size(12px);
				left: 2.5em;
				white-space: normal;
			}
		}
	}

	@media (max-width: $mobile-max-width) {
		ol {
			top: -50%;
		}
	}
</style>

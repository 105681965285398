<template>
  <div id="application" :class="{ mobile: isMobile }" @click="appClick">
		<header ref="appHeader">
			<Nav ref="nav" />
			<div id="app-title">
				<h1>Light a candle</h1><h2>in prayer</h2>
			</div>
			<div id="actions">
				<router-view name="actions"></router-view>
			</div>
		</header>
		<div id="app-content" ref="appContent" :style="{'min-height': appHeight + 'px'}">
			<VotiveStand ref="votiveStand" />
			<div id="modal" ref="appModal">
				<transition :name="transitionName" mode="out-in">
					<router-view name="modal"></router-view>
				</transition>
			</div>
		</div>
		<footer ref="appFooter">
			<a ref="cafodLogo" id="cafod-logo" target="_blank" href="https://cafod.org.uk" title="Cafod">
				<img src="../images/Cafod-Logo.svg" />
			</a>
			<router-view name="footer"></router-view>
		</footer>
  </div>
</template>

<script>
import VotiveStand from './VotiveStand.vue'
import Nav from './Nav.vue'
import { mapState } from 'vuex'
import mobileMixin from '../mixins/mobileMixin.js'
import iosInnerHeight from 'ios-inner-height'

export default {
	mixins: [mobileMixin],
  components: { VotiveStand, Nav },
	mounted() {
		window.addEventListener('resize', this.onResize)
		window.addEventListener('scroll', this.onResize)
		this.$nextTick(this.onResize)
		setTimeout(this.onResize, 100)
    this.track()
		this.ready = true
	},
	watch: {
		'$route' (to, from) {
			this.transitionName = this.getTransitionName(to.name)
		}
	},
	methods: {
		appClick() {
			this.$refs.nav.closeMenu()
		},
    track() {
      this.$ga.page(this.$router)
    },
		onResize() {
			const headerHeight = this.$refs.appHeader.getBoundingClientRect().height
			const footerHeight = this.$refs.appFooter.getBoundingClientRect().height
			const zoomLevel = document.documentElement.clientWidth / window.innerWidth
			this.appHeight = window.innerHeight * zoomLevel
			document.querySelector('html').style.height = this.appHeight + 'px'
		},
		getTransitionName(route) {
			if (['ready-to-place-candle', 'light-your-candle'].indexOf(route) !== -1)
				return ''
			else
				return 'fade'
		}
	},
	computed: {
		...mapState({
			currentCandle: state => state.candles.currentCandle
		})
	},
	data() {
		return {
			ready: false,
			appHeight: 0,
			transitionName: this.getTransitionName(this.$route.name)
		}
	}
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400');
	@import "../css/shared.scss";

	html {
		overflow: hidden;
	}

	html, body, #application {
		padding: 0;
		margin: 0;
    -webkit-text-size-adjust: 100%;
	}

  #application {
		position: relative;
	}

	body {
    	font-family: 'Montserrat', sans-serif;
	}

	body, header, footer {
		background: #0E152F;
	}

	.action-position {
		position: absolute;
		top: rel-size(120px);
		left: 50%;
		transform: translateX(-50%);
	}

	h1, h2, h3, h4 {
		font-weight: 300;
	}

	header {
		position: absolute;
		top: 0;
		left: 0;
		height: rel-size(181px);
    width: 100%;
    z-index: 2;
		text-align: center;


		.action {
			@extend .cta;
			width: rel-size(384px);
		  box-sizing: border-box;
			@extend .action-position;

			p {
				margin-top: 0;
			}

			.controls {
				/*height: rel-size(14px); */
				.close {
					position: absolute;
					top: rel-size(1px);
					right: rel-size(6px);
					width: rel-size(14px);
					img {
						width: 100%;
					}
				}
			}
			h3 {
				font-size: rel-size(22px);
				font-family: 'Montserrat', sans-serif;
				margin: 0 auto;

				&:after {
					content: '';
					display: block;
					border-bottom: 1px solid #707590;
					width: 3em;
					margin: 0.5em auto;
				}
			}
		}
	}

	.candle-content {
		width: rel-size(384px);
		margin: auto;
	}

	button {
		font-family: 'Montserrat', sans-serif;
	}
	
	footer {
		position: absolute;
		bottom: 0;
		left: 0;
		height: rel-size(91px);	
    width: 100%;
    z-index: 2;
		color: #707590;

		.legal {
			font-size: rel-size(10px);
			padding: 0 4em 1em;
			margin-top: 8em;

			a {
				text-decoration: none;
				color: #707590;
			}
		}

		#cafod-logo {
			margin-left: rel-size(36px);
			position: relative;
    	top: 50%;
    	transform: translateY(-50%);
			height: rel-size(36.39px);
			width: rel-size(285px);
			display: block;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

  #app-content {
		position: relative;
	}

	#app-title {
		margin-top: rel-size(32px);

		h1, h2, p {
			color: #707590; 
		}

		h1, h2 {
			margin-top: rel-size(14px);	
			margin-bottom: 0.25em;
			font-size: rel-size(28px);
			display: inline-block;
			font-weight: 400;
		}
	
		h1 {
			margin-right: 0.25em;
		}

		@media (max-width: 980px) {
			max-width: 70%;
			margin: auto;

			h1 {
				font-size: rel-size(30px);
				margin-top: rel-size(30px);	
				display: block;
				margin-right: 0;
				margin-bottom: 0;
			}

			h2 {
				font-size: rel-size(24px);
				display: block;
				margin-top: 0;
			}
		}
	}

</style>

<template>
	<div id="ready-to-place-candle" @click="candleClick" :style="{'min-height': $parent.appHeight + 'px'}">
		<div class="content">
			<div class="candle-wrapper" v-if="getCandleStatus == 'success' || activateCandleStatus == 'success'">
				<div class="candle-position">
					<candle :candle="candle" :isLit="true" :isLighting="true" v-if="candleVisible" :click="candleClick" />
				</div>
				<div class="candle-content">
					<h2 class="name">{{candle.name}}</h2>
					<div class="message">
						{{candle.message}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import helpers from '../helpers'
	import Candle from './Candle'
	export default {
		props: ['candleGuid'],
		computed: {
			...mapState({
				candle: state => state.candles.candle,
				activateCandleStatus: state => state.candles.status.activateCandle,
				getCandleStatus: state => state.candles.status.getCandle
			}),
			candleVisible() {
				return this.candle != null && this.candle.status == 'active'
			}
		},
		methods: {
			candleClick() {
				this.$router.push({ name: 'place-your-candle', params: { candleGuid: this.$route.params.candleGuid }})
			}
		},
		watch: {
			getCandleStatus() {
				if (this.getCandleStatus == 'success') {
					if (this.candle.status == 'placed') {
						this.$store.commit('candles/setError', 'Your candle has already been placed')
					} else if (this.candle.status == 'pending') {
						this.$store.commit('candles/setError', 'Your candle has not been activated')
					}
				}
			}
		},
		components: {
			Candle
		},
		created() {
			if (this.candle == null || this.candle.guid != this.$route.params.candleGuid) {
				this.$store.dispatch('candles/getCandle', this.$route.params.candleGuid)
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../css/shared.scss";

	#ready-to-place-candle {
		@extend .modal;
	}
</style>

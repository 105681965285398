import axios from 'axios'

export default {
	status: {
		PENDING: 'pending',
		SUCCESS: 'success',
		FAILURE: 'failure'
	},
  getStartRow (guid = '') {
		return axios.get("/api/candles/start" + (guid ? "?guid=" + guid : ""))
  },
  getCandles (from, to, slidesPerView, additional = false) {
		return axios.get("/api/candles?from=" + from + '&to=' + to + '&slides_per_view=' + slidesPerView + (additional ? '&additional=1' : '') )
  },
  createCandle (params) {
		return axios.post("/api/candles", { candle: params.candle, bypass_payment: true })
  },
  getCandle (candleGuid) {
		return axios.get("/api/candles/" + candleGuid)
  },
  activateCandle (candleGuid, q) {
		return axios.post("/api/candles/" + candleGuid + '/activate', { q: q })
  },
  trackCandle (candleGuid, q) {
    return axios.post("/api/candles/" + candleGuid + '/track', { q: q })
  },
  placeCandle (candleGuid, row, column) {
		return axios.post("/api/candles/" + candleGuid + '/place', { row: row, column: column })
  },
  getNavigationLinks () {
		return axios.get("/api/navigation_links")
  }
}

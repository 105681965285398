<template>
	<div class="votive-stand-wrapper">
		<div id="votive-stand">
			<div id="container" ref="container" @click="handleStandClick">
				<div class="votive-position" ref="votivePosition" :style="{top: `${top}%`, height: `${height}%`}">
					<div class="swiper-container" ref="swiperContainer" dir="rtl">
						<div class="swiper-wrapper" :style="{width: `${wrapperWidth}px`}">
							<div class="parallax-bg" :style="{ background: 'url(' + require('../images/' + background) + ') right repeat-x', backgroundSize: 'auto 100%'}"></div>
							<div :class="{rails: true, hideable: true, hidden: isStandHidden}" dir="ltr">
								<div class="rail rail1"></div>
								<div class="rail rail2"></div>
								<div class="rail rail3"></div>
							</div>
							<div dir="ltr" :class="{'swiper-slide': true, hideable: true, hidden: isStandHidden, active: activeRowIndex == slide[1].row}" v-bind:key="index" v-for="(slide, index) in rows">
								<div v-bind:key="i" v-for="i in 3" :class="`col${i}`">
									<candle :candle="slide[i]" :index="index" :showName="true" :isLit="true" :click="candleClick" :inHolder="true" />
								</div>
							</div>
						</div>
						<div :class="{'swiper-button-prev':true, hideable: true, hidden: isStandHidden}"></div>
						<div :class="{'swiper-button-next': true, hideable: true, hidden: isStandHidden}"></div>
						<div :class="{'swiper-scrollbar': true, hideable: true, hidden: isStandHidden}"></div>
					</div>
				</div>
			</div>
		</div>
		<div :class="{hideable: true, hidden: isStandHidden, abs: true}">
			<SliderNav :activeRowIndex="activeRowIndex" :maxRowIndex="maxRowIndex" :slidesPerView="calculateSlidesPerView()"/>
		</div>
		<div class="error" v-if="error">
			{{error}}
		</div>
		<!--
		<div class="diag">
			<select v-model="background">
				<option>Tiled-Wall-2b.png</option>
				<option>Tiled-Wall-2.png</option>
				<option>Wall-Tiled-1.png</option>
				<option>Warm-Abstract-1.png</option>
				<option>Warm-Abstract-2.png</option>
				<option>Warm-Abstract-3.png</option>
				<option>Warm-Abstract-4.png</option>
				<option>Warm-Abstract-5.png</option>
				<option>Tiled-Church-montage-defocus-opt.jpg</option>
				<option>Tiled-Church-montage.png</option>
				<option>Church-Abstract.jpg</option>
				<option>Tiled-Church-Wall-2.jpg</option>
			</select>
		</div>
		-->
	</div>
</template>

<script>
import helpers from '../helpers'
import Vue from 'vue'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper/dist/js/swiper.esm.bundle';
import Candle from './Candle'
import SliderNav from './SliderNav'

import { TweenMax } from 'gsap'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
		Candle,
		SliderNav
  },
  watch: {
		getCandlesStatus() {
			if (this.getCandlesStatus == 'success') {
				console.log('get candle success')
				this.swiper.update()
			}
		},
		placeCandleStatus() {
			if (this.placeCandleStatus == 'success') {
				this.$router.push({name: 'confirmation', params: { guid: this.candle.guid }})
			} else if (this.placeCandleStatus == 'failure' && this.error == "This holder has been reserved, please select another") {
				const from = this.$store.state.candles.from
				const to = from - this.limit + 1
				this.$store.dispatch('candles/appendCandles', { from: from, to: to, slidesPerView: this.calculateSlidesPerView(), additional: true, replace: true }) 
			} 
		},
		getStartRowStatus() {
			if (this.getStartRowStatus == 'success') {
				var from = this.$store.state.candles.from
				// Add extra empty rows at beginning (if needed)
				const additional = ['light-your-candle', 'ready-to-place-candle', 'place-your-candle'].indexOf(this.$route.name) !== -1
				var to = from - this.limit + 1
console.log('from is ' + from)
console.log('to is ' + to)
        if(from < 1  || to < 1) {
           from += (0 - to)
           to += (0 - to)
        }
console.log('from is now ' + from)
console.log('to is now ' + to)
			this.$store.dispatch('candles/appendCandles', { from: from, to: to, slidesPerView: this.calculateSlidesPerView(), additional: additional }) 
			}
		}
	},
  mounted: function(){
    this.track()
  },
	methods: {
    track() {
      this.$ga.page(this.$router) 
    },
		handleStandClick() {
			if (this.$route.name == 'confirmation') {
				this.$router.push({name: 'root'})
			}
		},
		candleClick(candle) {
			console.log('status', this.placeCandleStatus)
			if (this.$route.name == 'root') {
				if (!candle.empty) {
					this.$store.commit('candles/setCandle', null)
					this.$router.push({name: 'view-candle', params: { guid: candle.guid }})
				}
			} else if (this.$route.name == 'place-your-candle' && candle.empty && [null, 'failure'].indexOf(this.placeCandleStatus) !== -1) {
				this.$store.dispatch('candles/placeCandle', { row: candle.row, column: candle.column, guid: this.candle.guid })
			}
		},
		onWindowResize() {
			this.width = this.$refs.container.clientWidth
			if (this.swiper) {
				this.swiper.params.slidesPerView = this.calculateSlidesPerView() 
				this.swiper.update()
			}
		},
		calculateSlidesPerView() {
			 return window.innerWidth / (window.innerHeight * (this.height/100)) * 2
		},
		update() {
			if (this.swiper) {
				this.swiper.update && this.swiper.update()
				this.swiper.navigation && this.swiper.navigation.update()
				this.swiper.pagination && this.swiper.pagination.render()
				this.swiper.pagination && this.swiper.pagination.update()
			}
		},
		ready() {
			const guid = this.$route.params.guid
			this.$store.dispatch('candles/getStartRow', guid)
		},
		mountInstance() {
			console.log('mount Instance')
			const self = this;
			this.swiper = new Swiper('.swiper-container', {
        roundLengths: true,
				touchRatio: 0.5,
				freeModeMomentum: false,
				resistanceRatio: 100,
				grabCursor: true,
				mousewheel: true,
				keyboard: true,
				preloadImages: false,
				lazy: true,
				updateOnImagesReady: false,
				preloadImages: false,
				slidesPerView: self.calculateSlidesPerView(),
				freeMode: true,
				centeredSlides: false,
				speed: 1500,
				followFinger: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				scrollbar: {
					el: '.swiper-scrollbar',
					draggable: false,
				},
				on: {
					init() {
						self.ready()
					},
					setTranslate(t) {
						if (this.isTouch)
							self.translateOffset = this.translate
					},
					scroll() {
							self.translateOffset = this.translate
					},
					touchStart() {
						this.isTouch = true
					},
					touchEnd() {
						this.isTouch = false
					},
					slideNextTransitionStart() {
							self.translateOffset = this.translate
					},
					slidePrevTransitionEnd() {
							self.translateOffset = this.translate
					},
					slideChange() {
						//let nextIndex = Math.ceil((this.activeIndex+1)/self.limit) * self.limit
						const currentRow = self.rows[this.realIndex][1].row
						const threshold = Math.ceil(self.calculateSlidesPerView())
						console.log(threshold)
						if (currentRow == self.$store.state.candles.to + threshold && self.$store.state.candles.to > 0) {
							self.$store.dispatch('candles/appendCandles', { from: self.$store.state.candles.to - 1, to: Math.max(self.$store.state.candles.to - self.limit, 0) })	
						}
					}
				}
			})
		}
	},
  computed: {
		activeRowIndex() {
			if (!this.swiper || !this.rows.length)
				return 0
			return this.rows[this.swiper.realIndex][1].row
		},
		maxRowIndex() {
			return this.$store.state.candles.maxRow
		},
		getStartRowStatus() {
			return this.$store.state.candles.status.getStartRow
		},
		isStandHidden() {
			return ['root', 'place-your-candle', 'confirmation'].indexOf(this.$route.name) === -1
		},
		...mapState({
			candle: state => state.candles.candle,
			error: state => state.candles.error,
			getCandlesStatus: state => state.candles.status.getCandles,
			placeCandleStatus: state => state.candles.status.placeCandle,
			rows: state => state.candles.all
		}),
		wrapperWidth() {
			return this.width + Math.abs(this.translateOffset) + 500
		},
		offset() {
			return this.$store.state.candles.offset
		},
		container() {
			if (this.$refs.container)
				return this.$refs.container
		},
		swiperContainer() {
			if (this.$refs.swiperContainer)
				return this.$refs.swiperContainer
		},
	},
  created () {
		window.addEventListener('resize', this.onWindowResize)
			if (this.$route.name == 'place-your-candle' && (this.candle == null || this.candle.guid != this.$route.params.candleGuid)) {
				this.$store.dispatch('candles/getCandle', this.$route.params.candleGuid)
			}
  },
	mounted() {
		this.$nextTick(function() {
			this.onWindowResize()
		})
	},
  activated() {
      this.update()
	},
	updated() {
		this.update()
	},
	props: {
		top: {
			type: Number,
			default: 0
		},
		height: {
			type: Number,
			default: 100,
		},
		limit: {
			type: Number,
			default: 10
		}
	},
  beforeDestroy() {
		this.$nextTick(function() {
			if (this.swiper) {
				this.swiper.destroy && this.swiper.destroy()
				delete this.swiper
			}
		})
	},
	data() {
		return {
			isInitialFetch: true,
			isTouch: false,
			firstRow: 0,
			lastRow: 0,
			showMessage: false,
			width: window.innerWidth,
			rect: null,
			swiper: null,
			translateOffset: 0,
			background: 'Tiled-Wall-2b.png',
			virtualRows: {
				slides: [],
				offset: 0
			}, 
			swiperPadding: 0,
			//spritesheetJson: require('../images/spritesheet.json'),
			spritesheet: require('../images/spritesheet.png'),
			onTransitionEnd: null
		}
	},

	mounted() {
		this.$nextTick(this.mountInstance)
		//window.addEventListener('resize', this.resize)
		this.$nextTick(this.resize)
	}
}
</script>
<style lang="scss" scoped>

	@import "../css/shared.scss";

	.col2, .col4 {
		margin-left: 50%;
	}
	.swiper-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		box-sizing: border-box;
	}
	.votive-position {
		position: absolute;
		right: 0;
		left: 0;
	}
	.swiper-slide {
		height: 36%;
		top: 46%;
		.col1, .col2, .col3, .col4 {
			width: 50%;
			height: 30%;
			display: flex;
			justify-content: center;
			align-content: bottom;
			flex-direction: column;
			position: relative;
    	top: 8%;
		}
	}

  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
		background-repeat: norepeat;
    background-position: center right;
		background: #000;
  }
	.diag {
		color: #fff;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9999999999;
	}

  #container {
		position: relative;
		height: 100%;
	}

	.swiper-wrapper {
		will-change: transform;
		padding: 0;
		box-sizing: border-box;
	}

	.swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 25%;
    bottom: 2em;
    z-index: 50;
    height: 1em;
    width: 50%;
    background: rgba(255, 255, 255, 0.2);
		display: none;
	}

	.swiper-slide {
		box-sizing: border-box;
	}

	.rails {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;

		.rail {
			background: url(../images/Metal-Bar-Scratched-2-1920px.png) right repeat-x;
			background-size: auto 100%;
			position: absolute;
			right: 0;
			width: 100%;
			height: 3.5%;
		}

		.rail1 {
			top: 56.25%;
			background-position-x: right;
		}

		.rail2 {
			top: 67%;
			background-position-x: right 500px;
		}

		.rail3 {
			top: 78%;
			background-position-x: right 1000px;
		}
	}

	@keyframes fadeIn {
			from { opacity: 0; }
				to { opacity: 1; }
	}

	.inner-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.swiper-button-prev, .swiper-button-next {
		top: 63.5%;
		background-color: #0E152F;
    border-radius: 50%;
    width: 15vh;
    height: 15vh;
    background-size: 20%;
	}


	.hideable {
		transition: opacity 0.5s;
		&.hidden {
			opacity: 0;
			transition: opacity 1s;
		}
	}


	#votive-stand {
		overflow: hidden;
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
	}

	.swiper-button-next {
		left: 0; 
		  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23707590'%2F%3E%3C%2Fsvg%3E");
    transform: translateX(-45%);
    background-position: 65%;
	}
	.swiper-button-prev {
			right: 0; 
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23707590'%2F%3E%3C%2Fsvg%3E");
    transform: translateX(45%);
    background-position: 35%;
	}

.debug {
	color: #fff;
}

.error {
  @extend .cta;
	color: red;
	position: absolute;
	top: 40%;
	width: 50%;
	left: 25%;
	z-index: 1;
	text-align: center;
}

</style>

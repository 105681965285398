<template>
	<div class="slider-nav">
		<div class="slider-nav-bar"><div class="inner" :style="{width: width + '%', 'margin-left': left + '%'}"></div></div>
	</div>
</template>

<script>
	export default {
		props: ['maxRowIndex', 'activeRowIndex', 'slidesPerView'],
		computed: {
			left() {
				return (this.activeRowIndex/(this.maxRowIndex)*100) - (this.slidesPerView/this.maxRowIndex*100) 
			},
			width() {
				return Math.max(this.slidesPerView/this.maxRowIndex*100, 1)
			}
		}
	}
</script>

<style lang="scss" scoped>
  @import "../css/shared.scss";

	.slider-nav {
		z-index: 10;
		position: absolute;
		bottom: rel-size(91px);
		left: 50%;
		width: rel-size(384px);
		height: rel-size(37px);
		transform: translate3d(-50%, 50%, 0);
		border: 1px solid #707590;
		background: #0E152F;
		box-sizing: border-box;

		.slider-nav-bar {
			background: #282E4A;
			height: rel-size(8px);
			width: rel-size(350px);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			box-sizing: border-box;
			overflow: hidden;

			.inner {
				background: #596393;
				height: 100%;
				box-sizing: border-box;
				transition: width, margin-left 0.25s;
			}
		}
	}
</style>

import candlesApi from '../../api/candlesApi'


// initial state
const state = {
	maxRow: 0,
	from: 0,
	to: 0,
  candle: null,
	newCandle: null,
	//currentCandle: null,
  all: [],
	action: null,
	error: null,
	status: {
		getStartRow: null,
		getCandles: null,
		createCandle: null,
		activateCandle: null,
		placeCandle: null,
		getCandle: null,
    trackCandle: null
	}
}

// getters
const getters = {}

// actions
const actions = {
  getStartRow ({ commit, state }, candleGuid = '') {
		commit('setStatus', { getStartRow: candlesApi.status.PENDING })
    candlesApi.getStartRow(candleGuid).then((response) => {
      commit('setFrom', response.data)
			commit('setStatus', { getStartRow: candlesApi.status.SUCCESS })
    }).catch((error) => {
			commit('setStatus', { getStartRow: candlesApi.status.FAILURE })
		})
  },
  getCandle ({ commit, state }, candleGuid) {
		commit('setStatus', { getCandle: candlesApi.status.PENDING })
    candlesApi.getCandle(candleGuid).then((response) => {
      commit('setCandle', response.data)
			commit('setStatus', { getCandle: candlesApi.status.SUCCESS })
    }).catch((error) => {
			commit('setStatus', { getCandle: candlesApi.status.FAILURE })
		})
  },
  createCandle ({ commit, state }, params) {
		commit('setError', null) 
		commit('setStatus', { createCandle: candlesApi.status.PENDING })
    candlesApi.createCandle(params).then((response) => {
      commit('setCandle', response.data)
			commit('setStatus', { createCandle: candlesApi.status.SUCCESS })
    }).catch((error) => {
			commit('setError', error.response.data.error ? error.response.data.error : "There was a problem creating your candle") 
			commit('setStatus', { createCandle: candlesApi.status.FAILURE })
		})
  },
  activateCandle ({ commit, state }, params) {
		commit('setError', null) 
		commit('setStatus', { activateCandle: candlesApi.status.PENDING })
    candlesApi.activateCandle(params.guid, params.q).then((response) => {
      commit('setCandle', response.data.candle)
			commit('setStatus', { activateCandle: candlesApi.status.SUCCESS })
    }).catch((error) => {
			commit('setError', error.response.data.error ? error.response.data.error : "There was a problem activating your candle") 
			commit('setStatus', { activateCandle: candlesApi.status.FAILURE })
		})
  },
  trackCandle ({ commit, state }, params) {
    candlesApi.trackCandle(params.guid, params.q).then((response) => {
    })
  },
  placeCandle ({ commit, state }, params) {
		commit('setError', null) 
		commit('setStatus', { placeCandle: candlesApi.status.PENDING })
    candlesApi.placeCandle(params.guid, params.row, params.column).then((response) => {
      commit('setCandle', response.data.candle)
			commit('placeCandle', response.data.candle) 
			commit('setStatus', { placeCandle: candlesApi.status.SUCCESS })
    }).catch((error) => {
			commit('setError', error.response.data.error) 
			commit('setStatus', { placeCandle: candlesApi.status.FAILURE })
		})
  },
  appendCandles ({ commit, state }, { from = 0, to = 0, slidesPerView = 0, additional = false, replace = false }) {
		commit('setStatus', { getCandles: candlesApi.status.PENDING })
    candlesApi.getCandles(from, to, slidesPerView, additional).then((response) => {
			let _candles = []
			let keys = Object.keys(response.data.candles).reverse()
			for (var i = 0; i < keys.length; i++) {
				_candles.push(response.data.candles[keys[i]])
			}
			if (replace) {
				commit('setCandles', [])
			}
      commit('appendCandles', _candles)
      commit('setMaxRow', response.data.max_row)
      commit('setFrom', response.data.from)
      commit('setTo', response.data.to)
      commit('setAction', 'append')
			commit('setStatus', { getCandles: candlesApi.status.SUCCESS })
    }).catch((error) => {
			commit('setStatus', { getCandles: candlesApi.status.FAILURE })
		})
  },
	setLastOffset({commit}, lastOffset) {
		commit('setLastOffset', lastOffset)
	},
  prependCandles ({ commit, state }, { from = 0, to = 0 }) {
		commit('setStatus', { getCandles: candlesApi.status.PENDING })
    candlesApi.getCandles(from, to).then((response) => {
			let _candles = []
			let keys = Object.keys(response.data.candles)//.reverse()
			for (var i = 0; i < keys.length; i++) {
				_candles.push(response.data.candles[keys[i]])
			}
			console.log('call prepend')
      commit('prependCandles', _candles)
      commit('setFrom', response.data.from)
      commit('setTo', response.data.to)
      commit('setMaxRow', response.data.max_row)
      commit('setAction', 'prepend')
			commit('setStatus', { getCandles: candlesApi.status.SUCCESS })
    }).catch((error) => {
			console.log('prepend error', error)
			commit('setStatus', { getCandles: candlesApi.status.FAILURE })
		})
  },
	removeCandles({commit}, {start, count}) {
		commit('removeCandles', {start: start, count: count})
	}
}

// mutations
const mutations = {
  setCurrentCandle (state, candle) {
		state.currentCandle = candle
  },
  setCandle (state, candle) {
		state.candle = candle
  },
  setLastOffset (state, lastOffset) {
		state.lastOffset = lastOffset
  },
  setCandles (state, candles) {
		state.all = candles;
  },
  appendCandles (state, candles) {
		console.log('appending', candles)
		//state.all = candles
		state.all.push(...candles)
  },
  prependCandles (state, candles) {
		console.log('prepending', candles)
		state.all = candles
		//state.all = candles.concat(state.all)
  },
  setFrom (state, from) {
		state.from = from
  },
  setTo (state, to) {
		state.to = to
  },
  setAction (state, action) {
		state.action = action
  },
  setMaxRow (state, maxRow) {
		state.maxRow = maxRow
  },
	removeCandles(state, {start, count}) {
		state.all.splice(start, count)
	},
	setStatus(state, status) {
		state.status = Object.assign(state.status, status)
	},
	placeCandle(state, candle) {
		const r = state.all.find((r) => r[1].row == candle.row)
		candle.isPlacing = true
		r[candle.column] = Object.assign({}, candle)
	},
	setError(state, error) {
		state.error = error
	},
	setNewCandle(state, newCandle) {
		state.newCandle = newCandle
	},
	setNewCandleName(state, name) {
		state.newCandle.name = name
	},
	setNewCandleMessage(state, message) {
		state.newCandle.message = message
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

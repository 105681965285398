<template>			
	<div class="action">
		<div class="controls">
			<router-link :to="{name: 'root'}" class="close"><img src="../../images/Burger Menu Close.svg" /></router-link>
		</div>
		<button @click="createCandle">Enter your details</button>
	</div>
</template>

<script>
	import { mapState} from 'vuex'
	import config from '../../config.js'

	export default {
		computed: {
			...mapState({
				newCandle: state => state.candles.newCandle
			})
		},
		methods:{
			createCandle() {
				this.$store.dispatch('candles/createCandle', { candle: this.newCandle, bypassPayment: this.bypassPayment })	
			}
		},
		data() {
			return {
				bypassPayment: true,
				environment: config.environment
			}
		}
	}
</script>
